import axios from "axios";

export default {
    async getTodayTurni(dipendenteId: string, currentWeekdayNumber: number, todayDate: string) {
        const data = new FormData();
        data.append("limit", "100");
        data.append("where[turni_di_lavoro_dipendente]", `${dipendenteId})`);
        data.append("where[turni_di_lavoro_giorno]", `${currentWeekdayNumber})`);
        data.append("where[]", `turni_di_lavoro_data_inizio <= '${todayDate}'`);

        const response = await axios.post("rest/v1/search/turni_di_lavoro", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });

        //console.log(response.data);
        return response;
    },

    async getTurni(dipendenteId: string, currentWeekdayNumber: number, todayDate: string) {
        const data = new FormData();
        data.append("depth", "1");
        data.append("where[turni_di_lavoro_dipendente]", `${dipendenteId}`);
        //data.append("where[]", `turni_di_lavoro_data_inizio <= '${todayDate}'`);

        const response = await axios.post("rest/v1/search/turni_di_lavoro", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });

        //console.log(response.data);
        return response;
    },
};
