
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    // IonAvatar,
    IonIcon,
    IonButton,
    IonButtons,
    IonDatetime,
    IonSelect,
    IonSelectOption,
    menuController,
    modalController,
} from "@ionic/vue";
import { menu } from "ionicons/icons";

import { ref, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import moment, { duration } from "moment";

import { openToast } from "@/services/toast";

import apiLogin from "@/services/login";
import apiReparti from "@/services/reparti";
import apiTurni from "@/services/turni_lavoro";
import apiPresenze from "@/services/presenze";
import apiDipendenti from "@/services/dipendenti";

import PresenzaDetail from "@/components/presenze/PresenzaDetail.vue";

export default {
    name: "Simulatore",
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        // IonAvatar,
        IonIcon,
        IonButton,
        IonButtons,
        IonDatetime,
        IonSelect,
        IonSelectOption,
    },
    setup() {
        const router = useRouter();

        const user = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`));

        const dipendenteID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_id;
        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id;
        const dipendenteSelezionato = ref(dipendenteID);
        const showSelezioneRaparti = ref("0");

        const userImage = `${process.env.VUE_APP_BASE_URL}/uploads/${user.dipendenti_foto}`;

        const openMenu = () => {
            menuController.open("app-menu");
        };

        const data_presenza = ref(new Date().toISOString());

        // update the time every second
        /*const currentTime = ref(null);

        function interval() {
            setInterval(() => {
                currentTime.value = Intl.DateTimeFormat(navigator.language, {
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                }).format();
            }, 1000);
        }
        interval();*/

        /**
         * ! Carico tutti i dipendenti
         */
        const dipendenti = ref([]);
        async function loadDipendenti() {
            try {
                const res = await apiDipendenti.getDipendenti();
                //console.log(res);
                if (res.status === 200 && res.data.status === 0) {
                    dipendenti.value = res.data.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta dei dipendenti", "toast_danger");
                }
            } catch (error) {
                openToast("Si è verificato un errore durante la richiesta dei dipendenti", "toast_danger");
            }
        }
        loadDipendenti();

        /**
         * ! Dati dipendente per scelta reparto, se configurato
         */
        const dipendenteSettings = ref({
            dipendenti_dichiara_reparto: null,
        });
        async function getDipendenteData() {
            const res = await apiLogin.getUserDipendente(userID);
            if (res.status === 200 && res.data.status === 0) {
                const { dipendenti_dichiara_reparto } = res.data.data[0];

                /*dipendenteSettings.value = {
                    dipendenti_dichiara_reparto,
                };*/
                showSelezioneRaparti.value = dipendenti_dichiara_reparto;
            }
        }
        getDipendenteData();

        /**
         * ! Gestione reparto
         */
        const reparti = ref([]);
        const repartoSelezionato = ref(null);
        async function loadReparti(dipendente_id) {
            try {
                const res = await apiReparti.getUserReparti(dipendente_id);
                console.log(res);
                if (res.status === 0) {
                    reparti.value = res.data;
                    //Se sono associato ad un solo reparto lo seleziono in automatico e non serve che mostro la select reparti
                    if (res.data.length == 1) {
                        repartoSelezionato.value = res.data[0].reparti_id;
                    }
                }
            } catch (error) {
                console.log(error);
                openToast("Si è verificato un errore durante la richiesta dei reparti", "toast_danger");
                return;
            }
        }
        // Richiedo reparti per il dipendente selezioanto (default quello loggato)
        loadReparti(dipendenteSelezionato.value);

        /**
         * Setta il reparto, obbligatorio prima di inserire la presenza
         * @param reparto_id string of selected reparto
         */
        function setReparto(reparto_id) {
            repartoSelezionato.value = reparto_id;
        }

        /**
         * ! Orari dipendente giornata odierna
         */
        const turni = ref([]);
        const loadingTurni = ref(false);
        const currentWeekdayNumber = moment().day() + 1;
        const todayDate = moment().format("YYYY-MM-DD") + " 00:00:00";

        async function loadTurni(dipendente_id) {
            loadingTurni.value = true;
            try {
                //const res = await apiTurni.getTodayTurni(dipendente_id, currentWeekdayNumber, todayDate);
                const res = await apiTurni.getTurni(dipendente_id, currentWeekdayNumber, todayDate);
                //console.log(res);
                if (res.status === 200 && res.data.status === 0) {
                    turni.value = res.data.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta dei turni di lavoro", "toast_danger");
                }
            } catch (error) {
                openToast("Si è verificato un errore durante la richiesta dei turni di lavoro", "toast_danger");
            }
        }
        loadTurni(dipendenteSelezionato.value);

        /**
         * ! Stato dipendente, se presente o meno
         */
        const statoDipendente = ref("-");
        const ultimaPresenza = ref([]);
        async function loadDipendenteStatus(dipendente_id) {
            const date = moment().format("YYYY-MM-DD");
            try {
                const res = await apiPresenze.statoPresenza(dipendente_id, date);
                //console.log(res);
                if (res.status === 200 && res.data.status === 0) {
                    if (res.data.data.length === 0) {
                        statoDipendente.value = "Assente";
                    } else {
                        statoDipendente.value = "Presente";
                        ultimaPresenza.value = res.data.data[0];
                    }
                } else {
                    openToast("Si è verificato un errore durante la verifica della presenza odierna", "toast_danger");
                }
            } catch (error) {
                openToast("Si è verificato un errore durante la verifica della presenza odierna", "toast_danger");
            }
        }
        loadDipendenteStatus(dipendenteSelezionato.value);

        /**
         * ! Setta il dipendente selezionato e controlla le sue impostazioni (per mostrare in caso la scelta del reparto)
         */
        const currentDipendente = ref(null);
        function setDipendente(dipendente_id) {
            dipendenteSelezionato.value = dipendente_id;

            const foundedDipendente = dipendenti.value.find((dip) => dip.dipendenti_id == dipendente_id);

            if (foundedDipendente) {
                currentDipendente.value = foundedDipendente;
                showSelezioneRaparti.value = foundedDipendente.dipendenti_dichiara_reparto;

                //Richiedo presenza odierna dipendente selezionato
                loadDipendenteStatus(foundedDipendente.dipendenti_id);

                // Richiedo turni dipenente selezionato
                loadTurni(foundedDipendente.dipendenti_id);

                //Mostro o nascondo scelta reparto in base al dipendente selezionato
                if (foundedDipendente.dipendenti_dichiara_reparto === "1") {
                    //Richiedo reparti dipendente selezionato
                    loadReparti(foundedDipendente.dipendenti_id);
                } else {
                    reparti.value = [];
                    repartoSelezionato.value = null;
                }
            }
        }

        /**
         * ! Gestione output risposta simulazione
         */
        const output = ref([]);

        /**
         * ! Simula entrata
         */
        const loadingSimulatorEntrata = ref(false);
        const ora_entrata = ref(null);

        async function simulaEntrata() {
            loadingSimulatorEntrata.value = true;

            //Se devo comunicare prima il reparto controllo di averlo selezionato
            if (dipendenteSettings.value.dipendenti_dichiara_reparto === "1" && !repartoSelezionato.value) {
                loadingSimulatorEntrata.value = false;
                openToast("Prima di timbrare l'entrata devi selezionare il reparto di riferimento", "toast_danger");
                return;
            }
            if (!ora_entrata.value) {
                loadingSimulatorEntrata.value = false;
                openToast("Devi selezionare un orario di entrara prima di timbrare", "toast_danger");
                return;
            }
            if (!data_presenza.value) {
                loadingSimulatorEntrata.value = false;
                openToast("Devi selezionare una data prima di timbrare", "toast_danger");
                return;
            }

            // Scelta dipendente con cui timbrare, se non selezionato di default prende quello loggato
            const dipendente = dipendenteSelezionato.value;
            // Scelta data timbratura, se non selezionata di default prendo oggi
            const data_attuale = moment(data_presenza.value).format("YYYY-MM-DD");

            const entrata = moment(ora_entrata.value).format("HH:mm");

            try {
                const res = await apiPresenze.timbraEntrataSimulator(dipendente, entrata, repartoSelezionato.value, null, null, "SIMULATOR", data_attuale);
                //console.log(res);
                if (res.data.status === 0) {
                    output.value.push({
                        time: moment().format("HH:mm:ss"),
                        type: "ENTRATA",
                        result: "error",
                        text: res.data.txt,
                    });
                    openToast(res.data.txt, "toast_danger");
                } else {
                    output.value.push({
                        time: moment().format("HH:mm:ss"),
                        type: "ENTRATA",
                        result: "ok",
                        text: res.data.txt,
                    });
                    openToast(res.data.txt, "toast_success");
                    loadDipendenteStatus(dipendente);
                }
            } catch (error) {
                console.log(error);
                openToast("si è verificato un errore durante la timbratura di entrata", "toast_danger");
            } finally {
                loadingSimulatorEntrata.value = false;
            }
        }

        /**
         * ! Simula uscita
         */
        const loadingSimulatorUscita = ref(false);
        const ora_uscita = ref(null);

        async function simulaUscita() {
            loadingSimulatorUscita.value = true;

            if (!ora_uscita.value) {
                loadingSimulatorUscita.value = false;
                openToast("Devi selezionare un orario di uscita prima di timbrare", "toast_danger");
                return;
            }

            // Scelta dipendente con cui timbrare, se non selezionato di default prende quello loggato
            const dipendente = dipendenteSelezionato.value;

            const uscita = moment(ora_uscita.value).format("HH:mm");
            const data_uscita = moment(data_presenza.value).format("YYYY-MM-DD");

            try {
                const res = await apiPresenze.chiudiPresenzaSimulator(dipendente, uscita, "SIMULATOR", data_uscita);
                //console.log(res);
                if (res.data.status === 0) {
                    output.value.push({
                        time: moment().format("HH:mm:ss"),
                        type: "USCITA",
                        result: "error",
                        text: res.data.txt,
                    });
                    openToast(res.data.txt, "toast_danger");
                } else {
                    output.value.push({
                        time: moment().format("HH:mm:ss"),
                        type: "USCITA",
                        result: "ok",
                        text: res.data.txt,
                    });
                    openToast(res.data.txt, "toast_success");
                    loadDipendenteStatus(dipendente);
                }
            } catch (error) {
                console.log(error);
                openToast("si è verificato un errore durante la timbratura di uscita", "toast_danger");
            } finally {
                loadingSimulatorUscita.value = false;
            }
        }

        /**
         * ! Open presenza detail
         * @param presenza selected presenza object
         */
        /*async function openDetail(record) {
            //console.log(customer);
            if (record.result === "ok") {
                const modal = await modalController.create({
                    component: PresenzaDetail,
                    componentProps: {
                        data: record.presenza,
                    },
                });
                return modal.present();
            } else {
                openToast("Puoi visualizzare i dati solo delle presenze effettivamente salvate", "toast_danger");
            }
        }*/

        /**
         * ! Set user avatar image
         */
        function setImage(user) {
            if (user.dipendenti_foto) {
                return `${process.env.VUE_APP_BASE_URL}/uploads/${user.dipendenti_foto}`;
            } else {
                return `${process.env.VUE_APP_BASE_URL}/images/user.png`;
            }
        }

        /**
         * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
         */
        function dateFormat(date) {
            if (date && moment.isDate(new Date(date))) {
                return moment(date).format("DD/MM/YYYY");
            } else {
                return "-";
            }
        }

        /**
         * Return '-' if a customer field is null
         */
        function checkField(field) {
            if (!field || field === "") {
                return " - ";
            } else {
                return field;
            }
        }

        function printDay(dayNumber) {
            const weekDays = ["Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato", "Domenica"];
            return weekDays[dayNumber - 1] || "N/A";
        }

        onBeforeUnmount(() => {
            loadingSimulatorEntrata.value = false;
            loadingSimulatorUscita.value = false;

            //clearInterval(currentTime.value);
        });

        return {
            user,
            setImage,
            userImage,
            dateFormat,
            checkField,
            menu,
            openMenu,
            //currentTime,
            turni,
            loadingSimulatorEntrata,
            simulaEntrata,
            loadingSimulatorUscita,
            simulaUscita,
            ora_entrata,
            ora_uscita,
            showSelezioneRaparti,
            reparti,
            repartoSelezionato,
            setReparto,
            dipendenteSettings,
            output,
            statoDipendente,
            ultimaPresenza,
            dipendenti,
            dipendenteSelezionato,
            data_presenza,
            setDipendente,
            printDay,
        };
    },
};
